import { VContainer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VContainer, {
    attrs: {
      "fluid": ""
    }
  }, [_c(VToolbar, {
    staticClass: "elevation-0 transparent media-toolbar"
  }, [_c(VToolbarTitle, [_c('h5', [_vm._v(_vm._s(_vm.$t('productList')))])])], 1), _vm._v(" "), _c('ContentArea', [_c('Input', {
    staticClass: "mb-6",
    attrs: {
      "label": _vm.$t('keyword'),
      "clearable": "",
      "prepend-inner-icon": "mdi-magnify",
      "placeholder": _vm.$t('placeholder.keyword.marketplace')
    },
    model: {
      value: _vm.filterOptions.keywordText,
      callback: function callback($$v) {
        _vm.$set(_vm.filterOptions, "keywordText", $$v);
      },
      expression: "filterOptions.keywordText"
    }
  }), _vm._v(" "), _c('div', [_c('ProductItems', {
    attrs: {
      "options": _vm.tableOptions,
      "products": _vm.filteredItems,
      "loading": _vm.loading
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.tableOptions = $event;
      }
    }
  })], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };